
import { Component, Prop } from 'vue-property-decorator';
import VFormBuilder from './VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import StackedForm from '../../../mixins/StackedForm';
import { mixins } from 'vue-class-component';

@Component({
  components: { VFormBuilder },
})
export default class ImportForm extends mixins(StackedForm) {
  @Prop({ type: Boolean }) public required!: boolean;

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get items() {
    return [
      {
        name: 'file',
        type: InputType.File,
        label: 'common.form.file',
        rules: this.required ? 'required' : '',
        default: null,
        accept: 'text/json',
      },
    ];
  }

  public getData() {
    return { ...this.$refs.form.form };
  }
}
