
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Editable from '@/mixins/Editable';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import EditTableLoader from '@/components/table/EditTableLoader.vue';
import Notification from '@/mixins/Notification';
import EditInformationLoader from '@/components/information/EditInformationLoader.vue';
import StackedForm from '@/mixins/StackedForm';
import CustomerGroupProprtyForm from '@/components/customerGroupProperty/CustomerGroupProprtyForm.vue';
import { CustomerGroupProperty } from '@/interfaces/models/CustomerGroupProperty';
import ImportForm from '../../components/shared/form/ImportForm.vue';
import CustomerGroupPropertyApiService from '@/api/http/CustomerGroupPropertyApiService';
import { GroupTemplateName } from '@/enums/GroupTemplateName';

const customerGroupsProperty = namespace('customerGroupsProperty');

@Component({
  components: { CustomerGroupProprtyForm, EditInformationLoader, EditTableLoader, VWrapper, ImportForm },
})
export default class EditCustomerGroupsProperty extends mixins(Editable, StackedForm, Notification) {
  @customerGroupsProperty.Action('store') public store!: any;
  @customerGroupsProperty.Action('show') public show!: any;
  @customerGroupsProperty.Action('update') public update!: any;
  @customerGroupsProperty.State('active') public active!: CustomerGroupProperty;

  public groupTemplateName: string = '';
  public isSubmitted: boolean = false;

  public $refs!: {
    form: InstanceType<typeof CustomerGroupProprtyForm> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
    };
    templateForm: InstanceType<typeof ImportForm> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
    };
  };

  get title() {
    return this.editing ? 'customerGroupProprty.edit' : 'customerGroupProprty.create';
  }

  get listOfgroupTemplateName() {
    return [
      { value: GroupTemplateName.CUSTOMER_INVOICE_TEMPLATE, text: 'Customer Invoice Template' },
      { value: GroupTemplateName.MERCHANT_INVOICE_TEMPLATE, text: 'Merchant Invoice Template' },
      { value: GroupTemplateName.REFUND_TEMPLATE, text: 'Refund Template' },
      { value: GroupTemplateName.CUSTOMER_DELETE_TEMPLATE, text: 'Customer Delete Template' },
      { value: GroupTemplateName.CUSTOMER_RESET_TEMPLATE, text: 'Customer Reset Template' },
      { value: GroupTemplateName.CUSTOMER_EMAIL_VERIFICATION_TEMPLATE, text: 'Customer Email Verification Template' },
    ];
  }

  public async mounted() {
    if (this.editing) {
      this.$startLoading('customerGroupsProperty');
      await this.show({ id: this.id });
      this.$stopLoading('customerGroupsProperty');
    }
    if (this.editing && !this.active) {
      this.$router.push({ name: 'customerGroupsProperty.index' });
    }
  }

  public save() {
    if (this.editing && this.groupTemplateName) {
      this.importTemplate();
    }

    this.$refs.form.validate().then(async (res: boolean | boolean[]) => {
      if (this.isValid(res)) {
        this.$startLoading('customerGroupsProperty.save');
        const data: CustomerGroupProperty = { ...this.$refs.form.getData() };
        if (this.editing) {
          await this.update({ ...data, id: this.id });
        } else {
          await this.store({ ...this.$refs.form.getData() });
        }
        if (!this.active) {
          this.$stopLoading('customerGroupsProperty.save');
          this.$router.push({ name: 'customerGroupProprty.index' });
          return;
        }
        this.$stopLoading('customerGroupsProperty.save');
        this.$router.push({ name: 'customerGroupProprty.index' });
      } else {
        this.notifyError('notification.form');
      }
    });
  }

  public importTemplate() {
    this.$refs.templateForm.validate().then(async (res: boolean) => {
      if (res && this.$refs.templateForm.getData().file) {
        this.isSubmitted = true;
        const api: CustomerGroupPropertyApiService = new CustomerGroupPropertyApiService();
        const data: FormData = new FormData();
        data.append('template', this.$refs.templateForm.getData().file);

        try {
          this.$startLoading('customerGroupsProperty');
          await api.importTemplate({
            groupId: this.active._id,
            templateName: this.groupTemplateName ? this.groupTemplateName : 'defautName',
            file: data,
          });
        } finally {
          this.isSubmitted = false;
          this.$router.push({ name: 'customerGroupProprty.index' });
          this.$stopLoading('customerGroupsProperty');
        }
      }
    });
  }
}
