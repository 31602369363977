
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Editable from '@/mixins/Editable';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import Notification from '@/mixins/Notification';
import RoleForm from '../../components/role/RoleForm.vue';
import { Role } from '@/interfaces/models/Role';
import EditRoleLoader from '@/components/role/EditRoleLoader.vue';
import StackedForm from '@/mixins/StackedForm';

const role = namespace('role');

@Component({
  components: { EditRoleLoader, RoleForm, VWrapper },
})
export default class EditRole extends mixins(Editable, Notification, StackedForm) {
  @role.Action('store') public store!: any;
  @role.Action('show') public show!: any;
  @role.Action('update') public update!: any;
  @role.State('active') public active!: Role;

  public $refs!: {
    form: InstanceType<typeof RoleForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  get title() {
    return this.editing ? 'role.edit' : 'role.create';
  }

  get role() {
    if (this.editing && this.active) {
      return this.active;
    }

    return null;
  }

  public async mounted() {
    if (this.editing) {
      this.$startLoading('role');
      await this.show({ id: this.id });
    }

    if (this.editing && !this.active) {
      this.$router.push({ name: 'role.index' });
    }
    this.$stopLoading('role');
  }

  public save() {
    this.$refs.form.validate().then(async (res: boolean | boolean[]) => {
      if (this.isValid(res)) {
        if (this.editing) {
          await this.update({ ...this.$refs.form.getData(), id: this.active!._id });
        } else {
          await this.store({ ...this.$refs.form.getData() });
        }
        this.$router.push({ name: 'role.index' });
      } else {
        this.notifyError('notification.form');
      }
    });
  }
}
