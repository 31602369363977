
import { Component, Prop } from 'vue-property-decorator';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import StackedForm from '../../mixins/StackedForm';
import { mixins } from 'vue-class-component';

@Component({
  components: { VFormBuilder },
})
export default class CustomerGroupProprtyForm extends mixins(StackedForm) {
  @Prop({ type: Boolean }) public editing!: boolean;

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get items() {
    return [
      {
        name: 'customerGroupName',
        type: InputType.Text,
        label: 'customerGroupProprty.form.customerGroupName',
        rules: '',
      },
      {
        name: 'getDefaultOrderStatus',
        type: InputType.Select,
        label: 'customerGroupProprty.form.getDefaultOrderStatus',
        items: this.orderStatusTypes,
        rules: '',
      },
      {
        name: 'getInitialOrderStatusStandard',
        type: InputType.Select,
        label: 'customerGroupProprty.form.getInitialOrderStatusStandard',
        items: this.orderStatusTypes,
        rules: '',
      },
      {
        name: 'getInitialOrderStatusTerminal',
        type: InputType.Select,
        label: 'customerGroupProprty.form.getInitialOrderStatusTerminal',
        items: this.orderStatusTypes,
        rules: '',
      },
      {
        name: 'getInitialOrderStatusVirtual',
        type: InputType.Select,
        label: 'customerGroupProprty.form.getInitialOrderStatusVirtual',
        items: this.orderStatusTypes,
        rules: '',
      },
    ];
  }

  get orderStatusTypes() {
    return [
      { value: 'none', text: this.$t('orderStatus.none') },
      { value: 'created', text: this.$t('orderStatus.created') },
      { value: 'in_preparation', text: this.$t('orderStatus.in_preparation') },
      { value: 'ready', text: this.$t('orderStatus.ready') },
      { value: 'done', text: this.$t('orderStatus.done') },
      { value: 'in_payment', text: this.$t('orderStatus.in_payment') },
      { value: 'awaiting_confirmation', text: this.$t('orderStatus.awaiting_confirmation') },
    ];
  }

  public getData() {
    return { ...this.$refs.form.form };
  }
}
